<template>
  <div class="modalbox" :class="open ? 'opened' : ''">
    <div class="box_inner">
      <div class="close">
        <a href="#" @click="close"><i class="icon-cancel"></i></a>
      </div>
      <div class="description_wrap">
        <div class="about_popup_details">
          <div class="left">
            <div class="left_inner">
              <div class="author">
                <div class="avatar_image">
                  <img src="img/thumbs/1-1.jpg" alt="" />
                  <div class="main" data-img-url="img/about/1.jpg"></div>
                </div>
                <div class="short">
                  <h3 class="name">
                    {{ aboutData.firstName }}
                    <span class="coloring">{{ aboutData.lastName }}</span>
                  </h3>
                  <h3 class="job">
                    <AnimationText />
                  </h3>
                </div>
              </div>
              <div class="list">
                <ul>
                  <li>
                    <div class="list_inner">
                      <i class="icon-user"></i>
                      <span
                        >{{ aboutData.firstName }}
                        {{ aboutData.lastName }}</span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-calendar"></i>
                      <span>{{ aboutData.bithday }}</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-location"></i>
                      <span
                        ><a href="#" class="href_location">{{
                          aboutData.address
                        }}</a></span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-phone"></i>
                      <span
                        ><a href="#">{{ aboutData.phn }}</a></span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-mail-1"></i>
                      <span
                        ><a :href="`mailto:${aboutData.email}`">{{
                          aboutData.email
                        }}</a></span
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <div class="button full">
                <a href="img/about/1.jpg" download>Download CV</a>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="right_inner">
              <div class="biography">
                <div class="about_title">
                  <h3>
                    <span>About <span class="coloring">Me</span></span>
                  </h3>
                </div>
                <div class="text">
                  <p>
                    Hello everybody! 
                    My name is <span>Aiden de Castro.</span> I am an AI engineer, graphic designer,UI/UX designer, P.M. and
              I'm an entrepeneur with a small company called AI. Dev Group specialized in developing AI solutions for small to medium enterprises. With 6 years
              experience as a professional web developer and recently AI specialist and product manager, I have acquired
              the skills and knowledge for developing suitable solutions for every need our clients require.
                  </p>
                </div>
              </div>
              <div class="service">
                <div class="about_title">
                  <h3>
                    <span>Quality <span class="coloring">Services</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(service, i) in aboutData.serviceLists" :key="i">
                      <i class="icon-right-dir"></i>{{ service }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="prog_skill">
                <div class="about_title">
                  <h3>
                    <span
                      >Programming <span class="coloring">Skills</span></span
                    >
                  </h3>
                </div>
                <div class="oki_progress">
                  <div
                    class="progress_inner"
                    v-for="(skill, i) in aboutData.skills.programming"
                    :key="i"
                  >
                    <span
                      ><span class="label">{{ skill.name }}</span
                      ><span class="number">{{ skill.value }}%</span></span
                    >
                    <div class="background">
                      <div class="bar open">
                        <div
                          class="bar_in"
                          :style="{ width: `${skill.value}%` }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lang_skill">
                <div class="about_title">
                  <h3>
                    <span>Language <span class="coloring">Skills</span></span>
                  </h3>
                </div>
                <div class="circular_progress_bar">
                  <ul>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value=".95"></div>

                        <div class="title"><span>English</span></div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="0.5"></div>

                        <div class="title"><span>German</span></div>
                      </div>
                    </li>
                    <li>
                      <div class="list_inner">
                        <div class="myCircle" data-value="1.0"></div>

                        <div class="title"><span>Spanish</span></div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="timeline">
                <div class="about_title">
                  <h3>
                    <span
                      >Education <span class="coloring">Timeline</span></span
                    >
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(edu, i) in aboutData.education" :key="i">
                      <div class="list_inner">
                        <div class="time">
                          <span>{{ edu.year }}</span>
                        </div>
                        <div class="place">
                          <h3>{{ edu.unv }}</h3>
                          <span>{{ edu.degree }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="timeline">
                <div class="about_title">
                  <h3>
                    <span>Working <span class="coloring">Timeline</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(work, i) in aboutData.working" :key="i">
                      <div class="list_inner">
                        <div class="time">
                          <span>{{ work.year }}</span>
                        </div>
                        <div class="place">
                          <h3>{{ work.company }}</h3>
                          <span>{{ work.deg }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="partners">
                <div class="about_title">
                  <h3>
                    <span>I've <span class="coloring">worked with</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(partner, i) in aboutData.partnersLogos">
                      <div class="list_inner">
                        <img :src="partner" alt="" />
                        <a class="cavani_tm_full_link" href="#"></a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="testimonial">
                <div class="about_title">
                  <h3>
                    <span>Clients <span class="coloring">Feedback</span></span>
                  </h3>
                </div>
                <div class="list">
                  <swiper
                    :loop="true"
                    :slidesPerView="1"
                    :spaceBetween="10"
                    :loopAdditionalSlides="1"
                    :autoplay="{
                      delay: 6000,
                    }"
                    :modules="modules"
                    class="owl-carousel"
                  >
                    <swiper-slide>
                      <div class="list_inner">
                        <div class="text">
                          <i class="icon-quote-left"></i>
                          <p>
                            Great to work with! A professional who knows a anything related with the teck field from web development to hardware mantainance. I hired him to develop a web app for my company and he did a great job. also months later I hired him again to a server ralated issue and he managed to solve it in a few hours. I highly recommend him.
                          </p>
                        </div>
                        <div class="details">
                          <div class="image">
                            <div
                              class="main"
                              data-img-url="img/testimonials/1.jpg"
                            ></div>
                          </div>
                          <div class="info">
                            <h3>Carlos Vasquez</h3>
                            <span>Vasegin</span>
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="list_inner">
                        <div class="text">
                          <i class="icon-quote-left"></i>
                          <p>
                            Amazing Comunication. He was a part time web developer in my IT department and he was always on time and with a great attitude. He is a great team player and a great person. I highly recommend him.
                          </p>
                        </div>
                        <div class="details">
                          <div class="image">
                            <div
                              class="main"
                              data-img-url="img/testimonials/2.jpg"
                            ></div>
                          </div>
                          <div class="info">
                            <h3>Elizabeth Herrera</h3>
                            <span>XPC</span>
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div class="list_inner">
                        <div class="text">
                          <i class="icon-quote-left"></i>
                          <p>
                            Great and Skilled professional. He was a great comunicator, he is pacient and he is always willing to help.
                          </p>
                        </div>
                        <div class="details">
                          <div class="image">
                            <div
                              class="main"
                              data-img-url="img/testimonials/3.jpg"
                            ></div>
                          </div>
                          <div class="info">
                            <h3>Michael Lopez</h3>
                            <span>Meridian</span>
                          </div>
                        </div>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import AnimationText from "../AnimationText.vue";
export default {
  name: `AboutPopup`,
  components: {
    Swiper,
    SwiperSlide,
    AnimationText,
  },
  data() {
    return {
      open: false,
      // data
      aboutData: {
        firstName: "Aiden",
        lastName: "de Castro",
        bithday: "28.11.1999",
        address: "7801 NW 37th St. Doral, USA",
        phn: "+593 995 864 722",
        email: "aiden.ai@engineer.com",
        serviceLists: [
          "Web Apps Development",
          "AI Models Development",
          "UI/UX Design",
          "Big data Analysis",
          "Product Management",
        ],
        skills: {
          programming: [
            { name: "Python", value: "90" },
            { name: "JavaScript", value: "80" },
            { name: "React", value: "90" },
            { name: "Java", value: "60" },
            { name: "C++", value: "50" },
            { name: "HTML", value: "90" },
            { name: "CSS", value: "90" },
            { name: "SQL", value: "80" },
            { name: "Flask", value: "80" },
            { name: "Tensorflow", value: "80" },
            { name: "Pytorch", value: "80" },
            { name:"Figma", value: "90" },
            { name: "AWS", value: "80"},
            { name: "Azure", value: "50"}
          ],
          language: [
            { name: "Spanish", value: "95" },
            { name: "English", value: "80" },
            { name: "German", value: "50" },
          ],
        },
        education: [
          {
            year: "2023 - 2024",
            unv: "EC-Council",
            degree: "Certificate",
          },
          {
            year: "2020 - 2024",
            unv: "National Polytechnic School",
            degree: "Bachelor Degree",
          },
          {
            year: "2016 - 2018",
            unv: "Adobe design school",
            degree: "Certificate",
          },
        ],
        working: [
          {
            year: "2023 - running",
            company: "Omnicorp Media Group",
            deg: "Web apps developer",
          },
          {
            year: "2023 - running",
            company: "Ai. Dev Group",
            deg: "CEO, Product Manager",
          },
          {
            year: "2018 - 2019",
            company: "Meridian",
            deg: "English Teacher and translator",
          },
          {
            year: "2017 - 2019",
            company: "Freelancer",
            deg: "Web developer, graphic designer",
          },
          {
            year: "2016 - 2018",
            company: "Arzutex",
            deg: "Media Designer, social media manager, digital marketing",
          },
        ],
        partnersLogos: [
          "img/partners/1.png",
          "img/partners/2.png",
          "img/partners/3.png",
          "img/partners/4.png",
        ],
      },
    };
  },
  methods: {
    close() {
      this.open = false;
    },
  },
  mounted() {
    const aboutPopup = document.getElementById("aboutPopup");
    aboutPopup.addEventListener("click", () => {
      this.open = true;
    });

    const myCircle = document.querySelectorAll(".myCircle");
    myCircle.forEach((circle) => {
      let progress = circle.getAttribute("data-value");
      let span = document.createElement("span");
      span.className = "number";
      const value = `${progress * 100}%`;
      span.innerHTML = value;
      circle.appendChild(span);
      circle.style.setProperty("--progress", value);
      circle.setAttribute("aria-valuenow", value);
    });
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.testimonial {
  overflow: hidden;
}
.owl-carousel.swiper {
  overflow: unset;
}

.myCircle {
  --hue: 220;
  --holesize: 67%;
  --track-bg: hsl(233, 34%, 92%);

  min-width: 110px;
  min-height: 110px;
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  position: relative;
  margin-bottom: 5px;
}
.myCircle::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  z-index: -1;
  background: conic-gradient(
    var(--main-color) var(--progress, 0%),
    rgba(255, 255, 255, 0.15) var(--progress, 0%) 100%
  );

  mask-image: radial-gradient(
    transparent var(--holesize),
    black calc(var(--holesize) + 1px)
  );
}
</style>
