<template>
  <div class="section" id="news">
    <div class="section_inner">
      <div class="news swiper-section">
        <div class="main_title">
          <h3>Latest <span class="coloring">News</span></h3>
        </div>
        <div class="news_list">
          <swiper
            :loop="false"
            :slidesPerView="1"
            :spaceBetween="0"
            :loopAdditionalSlides="1"
            :autoplay="{
              delay: 6000,
            }"
            :navigation="{
              nextEl: '.my_next',
              prevEl: '.my_prev',
            }"
            :pagination="pagination"
            :breakpoints="{
              700: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }"
            @slideChange="onSlideChange"
            :modules="modules"
            class="swiper-container"
          >
            <swiper-slide
              class="swiper-slide"
              v-for="(data, i) in newsData"
              :key="i"
            >
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-3.jpg" alt="" />
                  <div class="main" :data-img-url="data.img"></div>
                  <a class="full_link news_popup" href="#"></a>
                </div>
                <div class="details">
                  <h3>
                    <a href="#">{{ data.title }}</a>
                  </h3>
                  <span
                    ><a href="#">{{ data.tag }}</a></span
                  >
                </div>

                <!-- News Popup Informations -->
                <div class="hidden_content">
                  <div class="news_popup_details">
                    <div class="top_image">
                      <img src="img/thumbs/4-2.jpg" alt="" />
                      <div class="main" :data-img-url="data.img"></div>
                    </div>
                    <div class="news_main_title">
                      <h3>{{ data.title }}</h3>
                      <span
                        ><a href="#">{{ data.tag }}</a></span
                      >
                      <div></div>
                    </div>
                    <div class="text">
                      <p v-for="(text, t) in data.des" :key="t">
                        {{ text }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- /News Popup Informations -->
              </div>
            </swiper-slide>
            <div class="swiper_progress fill">
              <div class="my_pagination_in">
                <span class="currentNews">0</span>
                <span class="pagination_progress"
                  ><span class="all allNews"><span></span></span
                ></span>
                <span class="totalNews">04</span>
              </div>
              <div class="my_navigation">
                <ul>
                  <li>
                    <a class="my_prev" href="#"
                      ><i class="icon-left-open-1"></i
                    ></a>
                  </li>
                  <li>
                    <a class="my_next" href="#"
                      ><i class="icon-right-open-1"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperSliderCustomSlider } from "../utilits";

export default {
  name: `NewsComponent`,
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    onSlideChange(swiper) {
      this.activeSlider = swiper.activeIndex + 1;
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".swiper_progress",
        type: "custom", // progressbar
        renderCustom: function (swiper, current, total) {
          swiperSliderCustomSlider(
            current,
            total,
            "currentNews",
            "totalNews",
            "allNews"
          );
        },
      },
    };
  },
  data() {
    return {
      newsData: [
        {
          title: "Building Our Brand: A Case Study",
          img: "img/news/1.jpg",
          tag: "Branding",
          des: [
            "AI dev Group  stands at the forefront of AI development, specializing in predictive modeling for marketing and data analysis. With its innovative solutions and commitment to client success, the company continues to drive digital transformation and empower businesses to harness the power of data for strategic advantage.",
            "AI dev Group  is a leading provider of AI solutions, offering a comprehensive suite of services that help businesses leverage the power of AI to drive growth and innovation. The company’s team of experts works closely with clients to develop customized solutions that address their unique needs and deliver measurable results.",
            "AI dev Group  is committed to helping businesses succeed in the digital age by providing cutting-edge AI solutions that drive growth and innovation. With its innovative approach to AI development, the company is helping businesses harness the power of data to gain a competitive edge and achieve their strategic goals.",
          ],
        },
        {
          title: "The Future of AI in Marketing",
          img: "img/news/2.jpg",
          tag: "Design",
          des: [
          "AI dev Group  stands at the forefront of AI development, specializing in predictive modeling for marketing and data analysis. With its innovative solutions and commitment to client success, the company continues to drive digital transformation and empower businesses to harness the power of data for strategic advantage.",
            "AI dev Group  is a leading provider of AI solutions, offering a comprehensive suite of services that help businesses leverage the power of AI to drive growth and innovation. The company’s team of experts works closely with clients to develop customized solutions that address their unique needs and deliver measurable results.",
            "AI dev Group  is committed to helping businesses succeed in the digital age by providing cutting-edge AI solutions that drive growth and innovation. With its innovative approach to AI development, the company is helping businesses harness the power of data to gain a competitive edge and achieve their strategic goals.",
          ],
        },
        {
          title: "Using APIS to Boost Your Business",
          img: "img/news/3.jpg",
          tag: "Media",
          des: [
          "AI dev Group  stands at the forefront of AI development, specializing in predictive modeling for marketing and data analysis. With its innovative solutions and commitment to client success, the company continues to drive digital transformation and empower businesses to harness the power of data for strategic advantage.",
            "AI dev Group  is a leading provider of AI solutions, offering a comprehensive suite of services that help businesses leverage the power of AI to drive growth and innovation. The company’s team of experts works closely with clients to develop customized solutions that address their unique needs and deliver measurable results.",
            "AI dev Group  is committed to helping businesses succeed in the digital age by providing cutting-edge AI solutions that drive growth and innovation. With its innovative approach to AI development, the company is helping businesses harness the power of data to gain a competitive edge and achieve their strategic goals.",
          ],
        },
        {
          title: "Predict the Future with AI",
          img: "img/news/4.jpg",
          tag: "Technology",
          des: [
          "AI dev Group  stands at the forefront of AI development, specializing in predictive modeling for marketing and data analysis. With its innovative solutions and commitment to client success, the company continues to drive digital transformation and empower businesses to harness the power of data for strategic advantage.",
            "AI dev Group  is a leading provider of AI solutions, offering a comprehensive suite of services that help businesses leverage the power of AI to drive growth and innovation. The company’s team of experts works closely with clients to develop customized solutions that address their unique needs and deliver measurable results.",
            "AI dev Group  is committed to helping businesses succeed in the digital age by providing cutting-edge AI solutions that drive growth and innovation. With its innovative approach to AI development, the company is helping businesses harness the power of data to gain a competitive edge and achieve their strategic goals.",
          ],
        },
        {
          title: "Your reason to use AI in your business",
          img: "img/news/5.jpg",
          tag: "Lifestyle",
          des: [
          "AI dev Group  stands at the forefront of AI development, specializing in predictive modeling for marketing and data analysis. With its innovative solutions and commitment to client success, the company continues to drive digital transformation and empower businesses to harness the power of data for strategic advantage.",
            "AI dev Group  is a leading provider of AI solutions, offering a comprehensive suite of services that help businesses leverage the power of AI to drive growth and innovation. The company’s team of experts works closely with clients to develop customized solutions that address their unique needs and deliver measurable results.",
            "AI dev Group  is committed to helping businesses succeed in the digital age by providing cutting-edge AI solutions that drive growth and innovation. With its innovative approach to AI development, the company is helping businesses harness the power of data to gain a competitive edge and achieve their strategic goals.",
          ],
        },
        {
          title: "Why to hire Us?",
          img: "img/news/6.jpg",
          tag: "Modern",
          des: [
          "AI dev Group  stands at the forefront of AI development, specializing in predictive modeling for marketing and data analysis. With its innovative solutions and commitment to client success, the company continues to drive digital transformation and empower businesses to harness the power of data for strategic advantage.",
            "AI dev Group  is a leading provider of AI solutions, offering a comprehensive suite of services that help businesses leverage the power of AI to drive growth and innovation. The company’s team of experts works closely with clients to develop customized solutions that address their unique needs and deliver measurable results.",
            "AI dev Group  is committed to helping businesses succeed in the digital age by providing cutting-edge AI solutions that drive growth and innovation. With its innovative approach to AI development, the company is helping businesses harness the power of data to gain a competitive edge and achieve their strategic goals.",
          ],
        },
      ],
    };
  },
};
</script>
